var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "12px" } },
    [
      _c(
        "div",
        {
          staticClass: "text-list-top",
          staticStyle: { "padding-bottom": "10px" },
          style: _vm.backCss,
        },
        [
          _c("span", { staticClass: "text-list-radio" }, [
            _c(
              "span",
              {
                class:
                  _vm.searchType == "search"
                    ? "text-list-radio-selected"
                    : "text-list-radio1",
                on: {
                  click: function ($event) {
                    _vm.searchType = "search"
                  },
                },
              },
              [
                _c(
                  "span",
                  { style: _vm.searchType == "search" ? "border:none" : "" },
                  [_vm._v("查找")]
                ),
              ]
            ),
            _vm.checkPermission("tranlate:replace")
              ? _c(
                  "span",
                  {
                    class:
                      _vm.searchType == "change"
                        ? "text-list-radio-selected"
                        : "text-list-radio2",
                    on: {
                      click: function ($event) {
                        _vm.searchType = "change"
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        style:
                          _vm.searchType == "revisionText" ? "border:none" : "",
                      },
                      [_vm._v("替换")]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm.searchType == "search"
        ? [
            _c(
              "div",
              { staticStyle: { display: "flex", "padding-top": "10px" } },
              [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c(
                      "a-input-group",
                      {
                        staticStyle: { display: "flex" },
                        attrs: { compact: "" },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            model: {
                              value: _vm.type,
                              callback: function ($$v) {
                                _vm.type = $$v
                              },
                              expression: "type",
                            },
                          },
                          [
                            _c("a-select-option", { attrs: { value: 0 } }, [
                              _vm._v("\n              原文\n            "),
                            ]),
                            _c("a-select-option", { attrs: { value: 1 } }, [
                              _vm._v("\n              译文\n            "),
                            ]),
                          ],
                          1
                        ),
                        _c("a-input-search", {
                          staticStyle: { width: "95%" },
                          attrs: { placeholder: "请输入" },
                          on: {
                            search: function ($event) {
                              return _vm.search(0)
                            },
                          },
                          model: {
                            value: _vm.text,
                            callback: function ($$v) {
                              _vm.text = $$v
                            },
                            expression: "text",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "padding-top": "15px",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { size: "small" },
                        on: { click: _vm.clear },
                      },
                      [_vm._v("重置")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "font-size": "12px" },
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.search(1)
                          },
                        },
                      },
                      [_vm._v("查找所有章节")]
                    ),
                    _c(
                      "a-button",
                      {
                        staticStyle: {
                          "margin-left": "15px",
                          "font-size": "12px",
                        },
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.search(0)
                          },
                        },
                      },
                      [_vm._v("查找当前章节")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "text-list-bottom",
                style: _vm.buttonType
                  ? `padding-top:25px;${
                      _vm.allHeight
                        ? "max-height:" +
                          (_vm.bottomHeight - 255) +
                          "px !important"
                        : ""
                    }`
                  : `padding-top:10px;${
                      _vm.allHeight
                        ? "max-height:" +
                          (_vm.bottomHeight - 240) +
                          "px !important"
                        : ""
                    }`,
                attrs: { id: "search-modal" },
              },
              [
                _vm.searchList.length
                  ? _c(
                      "div",
                      {
                        staticClass: "open-all-annotation-list",
                        style: _vm.buttonType ? "top:25px" : "",
                      },
                      [
                        _vm.textDefaultKey.length !=
                        _vm.searchList
                          .map((c) => c.chapter.length)
                          .reduce((prev, cur) => {
                            return prev + cur
                          })
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openList.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("全展开/收起")]
                            )
                          : _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.closeList.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v("全展开/收起")]
                            ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.searchList, function (c) {
                  return _c(
                    "div",
                    { key: c.chapterId },
                    [
                      _vm.buttonType
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                color: "rgba(0, 0, 0, 0.85)",
                                "padding-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "bold" } },
                                [
                                  _vm._v(
                                    "第" + _vm._s(c.chapterOrder - 0) + "话"
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [_vm._v(_vm._s(`共${c.num}处`))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      c.chapter.length
                        ? _c(
                            "a-collapse",
                            {
                              attrs: { bordered: false },
                              on: { change: _vm.textDefaultKeyChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "expandIcon",
                                    fn: function (props) {
                                      return [
                                        _c("a-icon", {
                                          attrs: {
                                            type: "caret-right",
                                            rotate: props.isActive ? 90 : 0,
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.textDefaultKey,
                                callback: function ($$v) {
                                  _vm.textDefaultKey = $$v
                                },
                                expression: "textDefaultKey",
                              },
                            },
                            _vm._l(c.chapter, function (page, pageIdx) {
                              return _c(
                                "a-collapse-panel",
                                {
                                  key: page.pageId + "-" + c.chapterId,
                                  style: _vm.customStyle,
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(`第${page.sequenceNo}页`)
                                        ),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              `共${page.textAreas.length}处`
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._l(page.textAreas, function (item) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        class:
                                          _vm.selectTextId === item.id
                                            ? "local-content-list-show local-content-list-show-selected"
                                            : "local-content-list-show",
                                        attrs: { id: `search-${item.id}` },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "local-content-list-num",
                                          },
                                          [
                                            _c("div", [
                                              _vm._v(_vm._s(item.sequenceNo)),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "local-content-list" },
                                          [
                                            _vm.type
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.auditText ||
                                                        item.revisionText ||
                                                        item.translateText
                                                    )
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(item.originalText)
                                                  ),
                                                ]),
                                            _c(
                                              "a",
                                              {
                                                staticStyle: {
                                                  "min-width": "30px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toText(
                                                      page,
                                                      item,
                                                      page.sequenceNo,
                                                      c
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("跳转")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]
        : [
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "padding-top": "15px",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "19%",
                        "line-height": "32px",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("查找")]
                  ),
                  _c("a-input", {
                    staticStyle: { width: "75%" },
                    model: {
                      value: _vm.searchValue,
                      callback: function ($$v) {
                        _vm.searchValue = $$v
                      },
                      expression: "searchValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "padding-top": "15px",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "19%",
                        "line-height": "32px",
                        "text-align": "right",
                      },
                    },
                    [_vm._v("替换为")]
                  ),
                  _c("a-input", {
                    staticStyle: { width: "75%" },
                    model: {
                      value: _vm.changeValue,
                      callback: function ($$v) {
                        _vm.changeValue = $$v
                      },
                      expression: "changeValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "padding-top": "15px",
                    "padding-bottom": "15px",
                    "justify-content": "end",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { "font-size": "12px" },
                          attrs: { size: "small" },
                          on: { click: _vm.clear },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: {
                            "margin-left": "15px",
                            "font-size": "12px",
                          },
                          attrs: { size: "small" },
                          on: { click: _vm.searchChange },
                        },
                        [_vm._v("查找")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "text-list-bottom",
                style: _vm.allHeight
                  ? `border-top:1px solid rgba(0,0,0,0.1);position:relative;max-height:${
                      _vm.bottomHeight - 310
                    }px !important`
                  : "border-top:1px solid rgba(0,0,0,0.1);position:relative",
              },
              [
                _vm.changeList.length
                  ? _c(
                      "div",
                      {
                        staticClass: "open-all-annotation-list",
                        staticStyle: { top: "12px" },
                      },
                      [
                        _vm.textChangeDefaultKey.length != _vm.changeList.length
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openChangeList.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("全展开/收起")]
                            )
                          : _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.closeChangeList.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("全展开/收起")]
                            ),
                      ]
                    )
                  : _vm._e(),
                _vm.changeList.length
                  ? _c(
                      "a-collapse",
                      {
                        attrs: { bordered: false },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "expandIcon",
                              fn: function (props) {
                                return [
                                  _c("a-icon", {
                                    attrs: {
                                      type: "caret-right",
                                      rotate: props.isActive ? 90 : 0,
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          766930589
                        ),
                        model: {
                          value: _vm.textChangeDefaultKey,
                          callback: function ($$v) {
                            _vm.textChangeDefaultKey = $$v
                          },
                          expression: "textChangeDefaultKey",
                        },
                      },
                      _vm._l(_vm.changeList, function (page) {
                        return _c(
                          "a-collapse-panel",
                          { key: page.pageId + "", style: _vm.customStyle },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(`第${page.order + 1}页`)),
                                ]),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "20px" } },
                                  [
                                    _vm._v(
                                      _vm._s(`共${page.textAreas.length}处`)
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._l(page.textAreas, function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  class:
                                    _vm.selectTextChangeId === item.id
                                      ? "local-content-list-show local-content-list-show-selected"
                                      : "local-content-list-show",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "local-content-list-num" },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(item.sequenceNo)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "local-content-list" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "pre-wrap",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.showText))]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeText(
                                                page,
                                                item,
                                                page.order
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("替换")]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }